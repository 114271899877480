<template>
    <v-container>
        <div class="alert alert-light alert-elevate" role="alert">
            <div class="alert-icon alert-icon-top">
                <i class="flaticon-warning kt-font-brand"></i>
            </div>
            <div class="alert-text">
                <p v-html="note"></p>
            </div>
        </div>
        <v-row class="align-center justify-end">
            <v-btn
                    color="primary"
                    dark
                    class="mb-2 text-body-2"
                    @click="goToCreateSlider"
            >{{ addSlide }}
            </v-btn>
        </v-row>
        <v-data-table
                v-if="courseHot && courseHot.length > 0"
                :headers="headers"
                :items="courseHot"
                class="elevation-1"
        >
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.img="{ item }">
                <img :src="item.img" width="200px">
            </template>
        </v-data-table>
        <CreateCourseHotDialog
                :dialog="showCreateSliderDialog"
                @onClickOutside="showCreateSliderDialog = false"
                @closePopup="closePopup"
        ></CreateCourseHotDialog>
    </v-container>
</template>

<script>
    import {mapGetters} from "vuex";
    import {
        GET_HOME_PAGE_CONF,
        SAVE_CONF,
        DELETE_COURSE_HOT
    } from "@/store/setting.module";
    import {GET_LIST_COURSE} from "@/store/courses.module";
    import CreateCourseHotDialog from "@/views/dialogs/create-course-hot-dialog.vue";
    import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
    export default {
        data() {
            return {
                note: "Chức năng cấu hình cho phép chọn khóa học hot",
                headers: [
                    {text: "STT", value: "id"},
                    {text: "Tên khóa học", value: "title"},
                    {text: "Ảnh bìa", value: "img", sortable: false},
                    {text: "Sửa/Xóa", value: "actions", sortable: false}
                ],
                editedIndex: -1,
                showCreateSliderDialog: false,
                addSlide: "Thêm Khóa Học",
            };
        },
        computed: {
            ...mapGetters({
                courseHot: "courseHot"
            }),
        },
        watch: {
            dialog(val) {
                val || this.close();
            }
        },
        mounted() {
            this.$store.dispatch(GET_HOME_PAGE_CONF);
            this.$store.dispatch(GET_LIST_COURSE);
        },
        components: {
            CreateCourseHotDialog
        },
      created() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cấu hình khóa học hot",titledad:'Cấu hình' }]);
      },
        methods: {
            goToCreateSlider() {
                this.showCreateSliderDialog = true;
            },
            async deleteItem(item) {
                this.$store.commit(DELETE_COURSE_HOT, item);
                let data = {
                    key: "course_hot",
                    value: this.courseHot
                };
                confirm("Are you sure you want to delete this item?") &&
                (await this.$store.dispatch(SAVE_CONF, data));
            },

            closePopup() {
                this.reloadConfig();
                this.showCreateSliderDialog = false;
            },
            reloadConfig() {
                this.$store.dispatch(GET_HOME_PAGE_CONF);
            }
        }
    };
</script>

<style lang="scss"></style>
