<template>
  <v-dialog
      v-model="showModel"
      scrollable
      @click:outside="onClickOutside"
      max-width="500"
      light
      class="pa-0 ma-0"
  >
    <v-card class="pa-0 ma-0">
      <v-form ref="form" lazy-validation>
        <v-container>
          <v-row>
            <v-col>
              <h4>Chọn Khóa học để thành khóa học hot</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                  :items="courses"
                  item-text="title"
                  item-value="id"
                  :label="'Chọn Khóa Học'"
                  v-model="course.id"
                  @change="changeContent"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="align-center justify-center">
            <v-btn color="primary" @click="saveSliderConfig">{{ save }}</v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {SAVE_CONF} from "@/store/setting.module";

export default {
  props: {
    dialog: {type: Boolean, default: false},
  },
  data() {
    return {
      show: false,
      edit: false,
      addTitle: 'Thêm title',
      chooseCourse: 'Chọn khóa học',
      save: 'Lưu',
      course: {}
    };
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses.courses,
    }),
    ...mapGetters({
      courseHot: "courseHot"
    }),
    showModel: {
      get: function () {
        return this.dialog;
      },
      // eslint-disable-next-line no-unused-vars
      set: function (newValue) {
      }
    }
  },
  components: {},
  methods: {
    changeContent(courseId) {
      this.courses.map(course => {
        if (course.id == courseId) {
          this.course.title = course.title
          this.course.slug = course.slug
          this.course.description = course.description
          this.course.id = course.id
          this.course.index = this.courseHot.length
          this.course.img = course.images.src
        }
      })
    },
    checkValue() {
      if (this.course.id === null && this.courseId !== null) {
        this.course.id = this.courseId;
        return true
      }
      return true
    },
    onClickOutside() {
      this.$emit("onClickOutside");
    },
    getDataPost() {
      let value = this.courseHot ? this.courseHot : [];
      value.map((slide, i) => {
        if (slide.id == this.course.id) {
          value[i] = this.course;
          this.edit = true;
        }
      });
      if (!this.edit) {
        value.push(this.course);
      }
      let data = {
        key: "course_hot",
        value: value
      };
      return data;
    },
    saveSliderConfig() {
      let check = this.checkValue();
      if (check) {
        let data = this.getDataPost()
        this.$store.dispatch(SAVE_CONF, data).then(data => {
          if (data.status) {
            this.$emit("closePopup");
            this.$toasted.success('Lưu thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 1000
            });
          }
        });
        this.edit = false
      }
    },
  }
};
</script>
